/**
 * Increases the size of an image when clicked and adjusts the layout accordingly.
 *
 * This function adds a click event listener to each image container element with the class 'single_img'.
 * When an image is clicked, it toggles a class 'js-bigger' on the clicked element.
 * If the clicked element does not have the 'js-bigger' class, it increases the size of the image and adjusts the layout.
 * It sets the height of the main selector element to match the height of the image, hides overflow on the body,
 * and adjusts the z-index of header and projects container elements.
 * If the clicked element already has the 'js-bigger' class, it restores the initial size and layout.
 */
export function imageBigger() {
    const imagesContainerSelector = document.getElementsByClassName('single__img');
    const containerImgSelector = document.querySelector('.challenge');
    const headerSelector = document.querySelector('header');
    const projectsContainer = document.querySelector('.projects__container');
    const mainSelector = document.querySelector('.concept__img-container');
    let imageElementHeight = containerImgSelector.clientHeight;

    for (let i = 0; i < imagesContainerSelector.length; i++) {
        const imageSelector = imagesContainerSelector[i];
        imageSelector.addEventListener('click', function(e) {
            const event = e.currentTarget;
            if (!event.classList.contains('js-bigger')) {
                event.classList.add('js-bigger');
                mainSelector.style.height = imageElementHeight + 'px';
                document.body.style.overflow = 'hidden';
                headerSelector.style.zIndex = '0';
                projectsContainer.style.zIndex = '-1';
            } else {
                event.classList.remove('js-bigger');
                mainSelector.style.height = 'initial';
                document.body.style.overflow = 'initial';
                headerSelector.style.zIndex = '3';
                projectsContainer.style.zIndex = 'initial';
            }
        });
    }
}