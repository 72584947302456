import {fixMenu} from "./components/fixMenu";
import {imageBigger} from "./components/imageBigger.js";
import {cardHover} from "./components/cardHoverEffect";

document.documentElement.classList.add("js-enabled");
const navMenu = document.querySelector('nav');

function main() {
    navMenu.addEventListener("click", () => {
        fixMenu();
    });
    cardHover('.project');
    imageBigger();
}

main();